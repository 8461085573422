export default () => ({
  links: [
    {
      id: 1,
      title: "Contact Us",
      url: "/contact-us",
      show: false,
      type: 1,
      children: [
        {
          name: "4F Yuchengco Tower I 484 Quintin Paredes St. Binondo, Manila",
          url: null,
          image: "/assets/bottombar/location-icon.png",
          alt: "Office Location",
        },
        {
          name: "(63-2)8628-8600",
          url: null,
          image: "/assets/bottombar/phone-icon.png",
          alt: "Office Phone",
        },
        {
          name: "cxc@malayan.com",
          url: 'mailto:cxc@malayan.com',
          image: "/assets/bottombar/mail.svg",
          alt: "CXC Malayan",
        },
      ],
    },
    {
      id: 1,
      title: "About Us",
      url: null,
      show: false,
      type: 2,
      children: [
        {
          name: "Company Background",
          alt: "Company Background",
          url: "/about-us#intro",
        },
        {
          name: "YGC",
          alt: "YGC",
          url: "/about-us#ygc",
        },
        {
          name: "Our Partners",
          alt: "Our Partners",
          url: "/about-us#partners",
        },
        {
          name: "History",
          alt: "History",
          url: "/about-us#history",
        },
      ],
    },
    {
      id: 2,
      title: "Products",
      url: null,
      show: false,
      type: 2,
      children: [
        {
          name: "Assets and Property",
          alt: "Assets and Property",
          url: "/products/assets-and-property",
        },
        {
          name: "Personal and Family",
          alt: "Personal and Family",
          url: "/products/personal-and-family",
        },
        {
          name: "Lifestyle",
          alt: "Lifestyle",
          url: "/products/lifestyle",
        },
      ],
    },
    {
      id: 3,
      title: "Claims and Support",
      url: null,
      show: false,
      type: 2,
      children: [
        {
          name: "Claim Process",
          url: "/claims-and-support/claim-process",
        },
        {
          name: "Claim Form",
          url: "/claims-and-support/claim-form",
        },
        {
          name: "FAQs",
          url: "/claims-and-support/faqs",
        },
        {
          name: "CX Handbook",
          url: "/contact-us",
        },
      ],
    },
    {
      id: 4,
      title: "News",
      url: null,
      show: false,
      type: 2,
      children: [
        {
          name: "Car",
          url: "/news/category/cars",
        },
        {
          name: "Business",
          url: "/news/category/business",
        },
        {
          name: "Home",
          url: "/news/category/home",
        },
        {
          name: "Travel",
          url: "/news/category/travel",
        },
        {
          name: "Finance",
          url: "/news/category/finance",
        },
      ],
    },
  ],
})
