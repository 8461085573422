
export default {
  props: {
    type: {
      type: String,
      default: "home",
    },
  },
  data: () => ({
    active_route: "",
    hasHandbook: true,
  }),
  methods: {
    showBottomCta() {
      if (this.mobile) {
        if (this.$route.fullPath == "/products") return false;
        if (
          this.$route.fullPath ==
          "/products/personal-and-family/vital-cover/application"
        )
          return false;
        if (
          this.$route.fullPath ==
          "/products/assets-and-property/home-protect/application"
        )
          return false;
        if (
          this.$route.fullPath ==
          "/products/assets-and-property/home-protect-lite/application"
        )
          return false;
        if (
          this.$route.fullPath ==
          "/products/assets-and-property/automaster/application"
        )
          return false;
        if (
          this.$route.fullPath ==
          "/products/assets-and-property/motoone/application"
        )
          return false;
        if (
          this.$route.fullPath ==
          "/products/assets-and-property/ctpl/application"
        )
          return false;
        if (
          this.$route.fullPath ==
          "/products/lifestyle/travel-master/application"
        )
          return false;
        if (
          this.$route.fullPath == "/products/lifestyle/travel-lite/application"
        )
          return false;
        if (this.$route.fullPath == "/products/lifestyle/pet-care/application")
          return false;
      }
      return true;
    },
    getDetails(type) {
      let result = "";
      switch (type) {
        case "image":
          switch (this.type) {
            case "home":
              result = "/assets/bottombar/homecta.webp";
              break;
            case "faq":
              result = "/assets/bottombar/faq.webp";
              break;
            case "claims":
              result = "/assets/bottombar/contact-us.webp";
              break;
          }
          break;
        case "title":
          switch (this.type) {
            case "home":
              result = "Get Insured today!";
              break;
            case "faq":
              result = "Got Questions? <br> We’ve Got Answers";
              break;
            case "claims":
              result = "Need assistance? <br> Let us help";
              break;
          }
          break;
        case "description":
          switch (this.type) {
            case "home":
              result =
                "Insure the things you value the most with the no. 1 non-life insurance company in the country. We provide insurance for your ease of mind.";
              break;
            case "faq":
              result =
                "No matter what life throws your way, know that we are here to help you rebuild, get back on track and heal. #InsuretobeSure with Malayan Insurane today!";
              break;
            case "claims":
              result =
                "Reviewed our claims and processes but still have questions or concerns? Let us help. You can also call 8628-8628.";
              break;
          }
          break;
      }

      return result;
    },
  },
  mounted() {
    this.showBottomCta();
    setTimeout(() => {
      this.active_route = this.$route.params.product;
      if (!this.mobile) {
        document.getElementById(this.attr.cta).classList.add(this.attr.has_js);

        let targets = [
          `#${this.attr.cta} .${this.attr.left} .${this.attr.wrapper}`,
          `#${this.attr.cta} .${this.attr.right}`,
        ];
        this.animateElements(this, targets);
      }
    }, 600);
  },
};
